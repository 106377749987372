import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import imageURL from '../../images/viddl_logo.png';

const SEO = ({ description, keywords, title }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						keywords
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const metaKeywords = keywords || site.siteMetadata.keywords;

	return (
		<Helmet title={site.siteMetadata.title}>
			<html lang="hu" />
			<meta name="description" content={metaDescription} />
			<meta name="keywords" content={metaKeywords} />
			<meta
				name="facebook-domain-verification"
				content="v6zqce6yr5mi55z6a6yhp3ykdn89u4"
			/>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0, maximum-scale=1.0, shrink-to-fit=no"
			/>

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@viddl" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={imageURL} />

			<meta property="og:title" content={title} />
			<meta property="og:image" content={imageURL} />
			<meta property="og:description" content={description} />
			<meta property="og:site_name" content={title} />
		</Helmet>
	);
};

export default SEO;
