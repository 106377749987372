import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path
				fill="#2C1B6F"
				fillRule="nonzero"
				stroke="none"
				strokeWidth="1"
				d="M20.016 0H3.984A3.995 3.995 0 000 3.984v16.032A3.995 3.995 0 003.984 24h16.032A3.995 3.995 0 0024 20.016V3.984A3.995 3.995 0 0020.016 0zM7.683 10.5h8.634A4.576 4.576 0 0112 16.57a4.576 4.576 0 01-4.317-6.07zM21 19.5c0 .825-.675 1.5-1.5 1.5h-15c-.825 0-1.5-.675-1.5-1.5v-9h2.346A6.829 6.829 0 0012 18.82a6.829 6.829 0 006.654-8.32l2.346.002V19.5zM21 6h-3V3h3v3z"
				transform="translate(-1067 -5317) translate(0 5227) translate(970 49) translate(97 41)"
			/>
		</svg>
	);
}

export default Icon;
