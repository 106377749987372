import React from 'react';
import { Link } from 'gatsby';
import Logo from '../common/Logo';

const Header = () => (
	<header className="bg-primary p-3">
		<Link to="/" aria-label="ViddL">
			<Logo />
		</Link>
	</header>
);

export default Header;
