import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path
				fill="#2C1B6F"
				fillRule="nonzero"
				stroke="none"
				strokeWidth="1"
				d="M19.97 0H4.031A4.044 4.044 0 000 4.032V19.97A4.042 4.042 0 004.032 24H19.97A4.04 4.04 0 0024 19.971V4.032A4.042 4.042 0 0019.97 0zM9 19.5H6V9h3v10.5zM9 6H6V3h3v3zm10.5 13.5h-3v-6a1.5 1.5 0 00-3 0v6h-3V9h3v1.863C14.118 10.013 15.065 9 16.125 9c1.864 0 3.375 1.678 3.375 3.75v6.75z"
				transform="translate(-1018 -5317) translate(0 5227) translate(970 49) translate(48 41)"
			/>
		</svg>
	);
}

export default Icon;
