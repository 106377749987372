import React from 'react';
import PropTypes from 'prop-types';

export default function SocialLink(props) {
	const { href, label, isLast, children } = props;

	const spanClassName = isLast ? '' : 'mr-4';
	return (
		<span className={spanClassName}>
			<a
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				aria-label={label}
			>
				{children}
			</a>
		</span>
	);
}

SocialLink.defaultProps = {
	isLast: false,
};

SocialLink.propTypes = {
	href: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	isLast: PropTypes.bool,
};
