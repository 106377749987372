import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path
				fill="#2C1B6F"
				fillRule="nonzero"
				stroke="none"
				strokeWidth="1"
				d="M0 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3H3a3 3 0 00-3 3zm19.5 3h-3v3h3v3h-3v9h-3v-9h-3V9h3V4.5A1.5 1.5 0 0115 3h4.5v3z"
				transform="translate(-970 -5317) translate(0 5227) translate(970 49) translate(0 41)"
			/>
		</svg>
	);
}

export default Icon;
